<template>
  <div>
    <div
      class="flex justify-center w-full -ml-4 cursor-pointer "
      :class="isHovered ? '' : '-ml-'"
    
        @click="$router.push({name:'home'})"
    >
   <img src="@/assets/3.png" alt="logo" class="w-full h-36 object-cover">
      <!-- <h1 class="text-2xl font-bold text-white overflow-hidden" v-show="isHovered">
        Mars Games
      </h1> -->
    </div>

    <desk-sbar :isHovered="isHovered" />
  </div>
</template>

<script>
import DeskSbar from '../Shared/DeskSbar.vue'
// import LogoSvg from '../svg/LogoSvg.vue'

export default {
  props: ['isHovered'],
  components: { DeskSbar },
  data () {
    return {}
  }
}
</script>

<style></style>
