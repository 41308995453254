<template>
  <img src="@/assets/logo-h-w.png" alt="logo" class="object-cover" style="height: 42px;">
</template>

<script>
export default {
  components: []
}
</script>

<style></style>
