<template>
  <div
    class="bg-[#262653] text-white p-3 overflow-y-scroll overflow-x-hidden h-[80dvh]"
  >
    <ul>
      <li
        v-for="(category, index) in getCategoriesData"
        :key="index"
        class="flex items-center overflow-hidden flex-nowrap space-x-4 my-8 -ml-5 min-w-52 h-10 py-6 px-2 rounded-[25px] cursor-pointer"
        @click="
          $router.push({ name: 'category.show', params: { id: category.id } })
        "
      >
        <div class="bg-[#313169] rounded-full size-10">
          <img
            :src="category.category_img"
            :alt="category.name"
            class="size-full object-cover"
          />
        </div>
        <span
          v-show="isHovered"
          class="hover:text-[#484892] flex-nowrap overflow-hidden"
        >
          {{ category.name }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { items } from '@/fakers/menu'
import { useCategoryStore } from '@/store/category'
import { mapState, mapActions } from 'pinia'
export default {
  props: ['isHovered'],
  data () {
    return {
      items: items
    }
  },
  computed: {
    ...mapState(useCategoryStore, ['getCategoriesData'])
  },
  methods: {
    ...mapActions(useCategoryStore, ['fetchCategories'])
  },
  async created () {
    await this.fetchCategories();
  }
}
</script>

<style scoped>
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
