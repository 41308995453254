
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "category" */ '../views/pages/HomeView.vue')

    },
    {
        path: '/category/show/:id',
        name: 'category.show',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "category" */ '../views/pages/ShowCategory.vue')
    },
    {
        path: '/game/show/:id',
        name: 'game.show',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "game" */ '../views/pages/ShowGame.vue')
    },
    {
        path: '/search/results',
        name: 'search.results',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "game" */ '../views/pages/SearchResults.vue')
    },
    {
        path: '/user/login',
        name: 'user.login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/user/LoginView.vue')
    },
    {
        path: '/user/register',
        name: 'user.register',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "register" */ '../views/user/RegisterView.vue')
    },
    {
        path: '/contacts',
        name: 'contacts',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "contacts" */ '../views/pages/ContactUs.vue')
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/AboutUs.vue')
    },
    {
        path: '/terms',
        name: 'terms',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "terms" */ '../views/pages/TermsView.vue')
    },
    {
        path: '/policy',
        name: 'policy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "policy" */ '../views/pages/PolicyView.vue')
    },
    {
        path: '/subscribe',
        name: 'subscribe.page',
        component: () => import('../views/pages/Subscribe.vue')
    },
    {
        path: '/unsubscribe',
        name: 'unsubscribe.page',
        component: () => import('../views/pages/Unsubscribe.vue'),
        meta: { 
            requiresAuth: true
        },
    },
    // Profile.vue
    {
        path: '/user/profile',
        name: 'user.profile',
        component: () => import('../views/user/Profile.vue'),
        meta: { 
            requiresAuth: true
        },
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "policy" */ '../views/pages/NotFound.vue')

    }
]

export default routes