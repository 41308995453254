<template>
  <loading :isLoading="isLoading" />
  <div class="relative min-h-screen bg-[#151436]" :dir="direction">
    <div id="content-wrap">
      <div class="flex">
        <div
          class="w-full h-full overflow-y-auto scrol-y-body overflow-x-hidden lg:px-24 invisible-scrollbar relative min-h-screen bg-[#151436]"
        >
          <header>
            <desktop-nav
              class="hidden md:block"
              :class="isRtlLayout ? 'pr-[5.2rem]' : 'pl-[5.2rem]'"
              :dir="direction"
            />
            <mobile-nav class="md:hidden" />
          </header>
          <div
            class="w-full h-full mb-24 relative mt-20 md:mt-0 bg-[#151436]"
            :class="isRtlLayout ? 'md:pr-[5rem]' : 'md:pl-[5rem]'"
            :dir="direction"
          >
            <router-view :key="$route.fullPath" />
          </div>
        </div>

        <div
          class="hidden sidebar-container md:block"
          @mouseover="expandSidebar"
          @mouseout="collapseSidebar"
        >
          <div
            class="sidebar overflow-hidden h-[100dvh] bg-[#262653] md:w-[80px] fixed top-0 z-50 py-[40px] pl-5 pr-0 animate-in duration-700"
            :class="{
              hovered: isSidebarHovered,
              'right-0': isRtlLayout,
              'left-0': !isRtlLayout,
              'slide-in-from-left': !isRtlLayout,
              'slide-in-from-right': isRtlLayout,
            }"
          >
            <main-sidebar :isHovered="isSidebarHovered" />
          </div>
        </div>
      </div>
    </div>
    <section class="footer">
      <footer-view />
    </section>
  </div>
</template>

<script>
import DesktopNav from "./components/partials/DesktopNav.vue";
import FooterView from "./components/partials/FooterView.vue";
import MainSidebar from "./components/partials/MainSidebar.vue";
import MobileNav from "./components/partials/MobileNav.vue";
import Loading from '@/components/partials/Loading.vue';
import { mapActions } from "pinia";
import { useAuthStore } from '@/store/auth'
import { eventBus } from '@/eventBus';

export default {
  inject: ["isRtlLayout"],
  components: { FooterView, DesktopNav, MobileNav, MainSidebar, Loading },
  data() {
    return {
      isSidebarHovered: false,
      isLoading: false,
    };
  },
  computed: {
    direction() {
      return this.isRtlLayout ? "rtl" : "ltr";
    },
  },
  methods: {
    ...mapActions(useAuthStore, ["autoLogin", "clearData"]),
    expandSidebar() {
      this.isSidebarHovered = true;
    },
    collapseSidebar() {
      this.isSidebarHovered = false;
    },
  },
  async created() {
    eventBus.on('loading', (loading) => {
      this.isLoading = loading;
    });
    // get the phone from the local storage
    const phone = localStorage.getItem("phone");

    if (phone) {
      await this.autoLogin(phone);
    } else {
      this.clearData();
    }
  },

};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400&display=swap");

[dir="rtl"] .sidebar-container {
  position: relative;
}

[dir="ltr"] .sidebar.hovered {
  width: 300px;
  transform: translate(0);
}

[dir="rtl"] .sidebar.hovered {
  width: 300px;
  transform: translate(0);
}

.transition-width {
  transition: none;
}
/* Custom styling for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 2px; /* Set the width of the scrollbar */
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the thumb (the draggable part) */
  display: none;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the color of the track (the non-draggable part) */
}
/* Styles specific to RTL layout */
[dir="rtl"] .sidebar.right-0 {
  right: 0; /* Position the sidebar on the right for RTL */
  left: auto; /* Reset the left property */
}

/* Styles specific to LTR layout */
[dir="ltr"] .sidebar.left-0 {
  left: 0; /* Position the sidebar on the left for LTR */
  right: auto; /* Reset the right property */
}
</style>
